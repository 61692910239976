import RichTextLink from './RichTextLink/RichTextLink';
import RichText from './RichText/RichText';
import MarkdownText from './MarkdownText/MarkdownText';
import Zone from './Zone/Zone';
import Layout from './Layout/Layout';
import Content from './Content/Content';
import ContentVideo from './ContentVideo/ContentVideo.js';
import MainMenu from './MainMenu/MainMenu';
import Footer from './Footer/Footer';
import LanguageSelection from './LanguageSelection/LanguageSelection';
import TabbedContent from './TabbedContent/TabbedContent';
import AnnouncementBar from './AnnouncementBar/AnnouncementBar';
import SideBySideContainer from './SideBySideContainer/SideBySideContainer';
import SeriesSelector from './SeriesSelector/SeriesSelector';
import MiniNavigation from './MiniNavigation/MiniNavigation';
import PromoBlock from './Promo/Promo';
import SpotlightSlider from './SpotlightSlider/SpotlightSlider';
import SpotlightContentVideo from './SpotlightContentVideo/SpotlightContentVideo';
import SpotlightContentBlock from './SpotlightContentBlock/SpotlightContentBlock';
import SpotlightStickyBar from './SpotlightStickyBar/SpotlightStickyBar';
import InTheBoxBlock from './InTheBoxBlock/InTheBoxBlock';
import ProductGridBlock from './ProductGridBlock/ProductGridBlock';
import CollectionTabsBlock from './CollectionTabs/CollectionTabsBlock';
import ProductHighlights from './ProductHighlights/ProductHighlights';
import FeaturesTabBlock from './FeaturesTabBlock/FeaturesTabBlock';
import BrandGuaranteeBlock from './BrandGuaranteeBlock/BrandGuaranteeBlock';
import GalleryBlock from './GalleryBlock/GalleryBlock';
import FAQBlock from './FAQBlock/FAQBlock';
import BrushConfigurator from './BrushConfigurator/BrushConfigurator';
import ComparisonChartBlock from './ComparisonChartBlock/ComparisonChartBlock';
import SpotlightWaitlistBlock from './SpotlightWaitlistBlock/SpotlightWaitlistBlock';
import SpotlightPreorderBlock from './SpotlightPreorderBlock/SpotlightPreorderBlock';
import CollectionTabsDropdown from './CollectionTabs/CollectionTabsDropdownBlock';
import ProductSubNav from './ProductSubNav/ProductSubNav';
import CharacterBlock from './CharacterBlock/CharacterBlock';
import ProductRecommenderBlock from './ProductRecommender/ProductRecommender';
import ShowcaseBlock from './ShowcaseBlock/ShowcaseBlock';
import ArticleHeroBlock from './ArticleHeroBlock/ArticleHeroBlock';
import SkipNavigationBlock from './SkipNavigationBlock/SkipNavigationBlock';
import AppDownloadBlock from './AppDownloadBlock/AppDownloadBlock';
import ArticleCategoryHeroBlock from './ArticleCategoryHeroBlock/ArticleCategoryHeroBlock';
import RecentlyViewedProductsBlock from './RecentlyViewedProductsBlock/RecentlyViewedProductsBlock';
import AdditionalReadingBlock from './AdditionalReadingBlock/AdditionalReadingBlock';
import UserQuoteBlock from './UserQuoteBlock/UserQuoteBlock';
import ProductOverlay from './ProductOverlay/ProductOverlay';
import LoginBlock from './LoginBlock/LoginBlock';
import ForgotPasswordBlock from './ForgotPasswordBlock/ForgotPasswordBlock';
import ResetPasswordBlock from './ResetPasswordBlock/ResetPasswordBlock';
import RegisterProductBlock from './RegisterProductBlock/RegisterProductBlock';
import ErrorBlock from './ErrorBlock/ErrorBlock';
import ArticleBody from './ArticleBody/ArticleBody';
import CloudinaryImage from './CloudinaryImage/CloudinaryImage';
import YoutubeVideo from './YoutubeVideo/YoutubeVideo';
import CreateAccountBlock from './CreateAccountBlock/CreateAccountBlock';
import RegisterTypeCodeBlock from './RegisterTypeCodeBlock/RegisterTypeCodeBlock';
import ResponsiveImage from './ResponsiveImage/ResponsiveImage';
import ContactUsBlock from './ContactUsBlock/ContactUsBlock';
import PypestreamChat from './PypestreamChat/PypestreamChat';
import CookiesNotificationOverlay from './CookiesNotificationOverlay/CookiesNotificationOverlay';
import PressQuoteBlock from './PressQuoteBlock/PressQuoteBlock';
import SearchComponent from './SearchBlock/SearchComponent';
import ProfileBlock from './ProfileBlock/ProfileBlock';
import CongratulationsBlock from './CongratulationsBlock/CongratulationsBlock';
import ChangePasswordBlock from './ChangePasswordBlock/ChangePasswordBlock';
import LoginNavigationBlock from './LoginNavigationBlock/LoginNavigationBlock';
import DesignedByDentistBlock from './DesignByDentist/DesignedByDentist';
import AwardsBlock from './AwardsBlock/AwardsBlock';
import LegacyArticleHeroBlock from './LegacyArticleHeroBlock/LegacyArticleHeroBlock';
import ContentDetailsBlock from './ContentDetailsBlock/ContentDetailsBlock';
import ConsentBlock from './ConsentBlock/ConsentBlock';
import ThreeColumnsUserQuoteBlock from './ThreeColumnsUserQuoteBlock/ThreeColumnsUserQuoteBlock';
import ArticleShare from './ArticleShare/ArticleShare';
import ClicCarousel from './ClicCarousel/ClicCarousel';
import ClicVideo from './ClicVideo/ClicVideo';
import LegacyProductHighlightsBlock from './LegacyProductHighlightsBlock/LegacyProductHighlightsBlock';
import ArticleListBlock from './ArticleList/ArticlelistBlock';
import ArticleTableBlock from './ArticleTableBlock/ArticleTableBlock';
import SpotlightContest from './SpotlightContestBlock/SpotlightContestBlock';
import SpotlightContestThankYou from './SpotlightContestThankYouBlock/SpotlightContestThankYouBlock';
import FAQDetailsBlock from './FaqDetailsBlock/FaqDetailsBlock';
import ProductLegacyBody from './ProductLegacyBody/ProductLegacyBody';
import Disclaimer from './Disclaimer/Disclaimer';
import ProductVariantsRecommanderBlock from './ProductVariantsRecommanderBlock/ProductVariantsRecommanderBlock';
import SitemapBlock from './SitemapBlock/SitemapBlock';
import SitemapPage from './SitemapPage/SitemapPage';
import HTMLBlock from './HTMLBlock/HTMLBlock';
import SpotlightExperienceBlock from './SpotlightExperienceBlock/SpotlightExperienceBlock';
import PostPurchaseRegisterForm from './PostPurchaseRegisterForm/PostPurchaseRegisterForm';
import PrePurchase from './PrePurchase/PrePurchase';
import PrePurchaseResult from './PrePurchaseResult/PrePurchaseResult';
import PostPurchaseWriteReviewSection from './PostPurchaseWriteReviewSection/PostPurchaseWriteReviewSection';
import BreadCrumbs from './Breadcrumbs/Breadcrumbs';

import {ComponentFactoryConstants} from '../adapters/helpers/Constants';
import DoubleOptInVerificationBlock from './DoubleOptInVerificationBlock/DoubleOptInVerificationBlock';
import StartGuide from './StartGuide/StartGuide';
import StartGuideContentBlock from './StartGuide/StartGuideContentBlock/StartGuideContentBlock';
import pageProgressBar from './PageProgressBar/PageProgressBar'
import StartGuideShuffleBlock from './StartGuide/StartGuideShuffleBlock/StartGuideShuffleBlock';
import StartGuideSignupForm from './StartGuide/StartGuideSignupForm/StartGuideSignupForm'
import MiniContentBlock from './MiniContentBlock/MiniContentBlock.js';
import SwitchTab from './SwitchTab/SwitchTab';
import ArticleCarouselBlock from './ArticleCarouselBlock/ArticleCarouselBlock.js';
import DragSlider from './DragSlider/DragSlider';

class ComponentFactory {

  constructor() {
    this.modules = {};
    this.addModule(ComponentFactoryConstants.Zone, Zone);
    this.addModule(ComponentFactoryConstants.Layout, Layout);
    this.addModule(ComponentFactoryConstants.Content, Content);
    this.addModule(ComponentFactoryConstants.ContentVideo, ContentVideo);
    this.addModule(ComponentFactoryConstants.RichText, RichText);
    this.addModule(ComponentFactoryConstants.RichTextLink, RichTextLink);
    this.addModule(ComponentFactoryConstants.MarkdownText, MarkdownText);
    this.addModule(ComponentFactoryConstants.MainMenu, MainMenu);
    this.addModule(ComponentFactoryConstants.Footer, Footer);
    this.addModule(ComponentFactoryConstants.LanguageSelection, LanguageSelection);
    this.addModule(ComponentFactoryConstants.TabbedContent, TabbedContent);
    this.addModule(ComponentFactoryConstants.AnnouncementBar, AnnouncementBar);
    this.addModule(ComponentFactoryConstants.SideBySideContainer, SideBySideContainer);
    this.addModule(ComponentFactoryConstants.SeriesSelector, SeriesSelector);
    this.addModule(ComponentFactoryConstants.MiniNavigation, MiniNavigation);
    this.addModule(ComponentFactoryConstants.PromoBlock, PromoBlock);
    this.addModule(ComponentFactoryConstants.SpotlightSlider, SpotlightSlider);
    this.addModule(ComponentFactoryConstants.SpotlightContentVideo, SpotlightContentVideo);
    this.addModule(ComponentFactoryConstants.SpotlightContentBlock, SpotlightContentBlock);
    this.addModule(ComponentFactoryConstants.SpotlightStickyBar, SpotlightStickyBar);
    this.addModule(ComponentFactoryConstants.InTheBoxBlock, InTheBoxBlock);
    this.addModule(ComponentFactoryConstants.ProductGridBlock, ProductGridBlock);
    this.addModule(ComponentFactoryConstants.CollectionTabsBlock, CollectionTabsBlock);
    this.addModule(ComponentFactoryConstants.ProductHighlights, ProductHighlights);
    this.addModule(ComponentFactoryConstants.FeaturesTabBlock, FeaturesTabBlock)
    this.addModule(ComponentFactoryConstants.BrandGuaranteeBlock, BrandGuaranteeBlock);
    this.addModule(ComponentFactoryConstants.GalleryBlock, GalleryBlock);
    this.addModule(ComponentFactoryConstants.FAQBlock, FAQBlock);
    this.addModule(ComponentFactoryConstants.BrushConfigurator, BrushConfigurator);
    this.addModule(ComponentFactoryConstants.ComparisonChartBlock, ComparisonChartBlock);
    this.addModule(ComponentFactoryConstants.UserQuoteBlock, UserQuoteBlock);
    this.addModule(ComponentFactoryConstants.SpotlightWaitlistBlock, SpotlightWaitlistBlock);
    this.addModule(ComponentFactoryConstants.SpotlightPreorderBlock, SpotlightPreorderBlock);
    this.addModule(ComponentFactoryConstants.CollectionTabsDropdown, CollectionTabsDropdown);
    this.addModule(ComponentFactoryConstants.ProductSubNav, ProductSubNav);
    this.addModule(ComponentFactoryConstants.CharacterBlock, CharacterBlock);
    this.addModule(ComponentFactoryConstants.ProductRecommenderBlock, ProductRecommenderBlock);
    this.addModule(ComponentFactoryConstants.ShowcaseBlock, ShowcaseBlock);
    this.addModule(ComponentFactoryConstants.ArticleHeroBlock, ArticleHeroBlock);
    this.addModule(ComponentFactoryConstants.SkipNavigationBlock, SkipNavigationBlock);
    this.addModule(ComponentFactoryConstants.AppDownloadBlock, AppDownloadBlock);
    this.addModule(ComponentFactoryConstants.ArticleCategoryHeroBlock, ArticleCategoryHeroBlock);
    this.addModule(ComponentFactoryConstants.RecentlyViewedProductsBlock, RecentlyViewedProductsBlock);
    this.addModule(ComponentFactoryConstants.AdditionalReadingBlock, AdditionalReadingBlock);
    this.addModule(ComponentFactoryConstants.ProductOverlay, ProductOverlay);
    this.addModule(ComponentFactoryConstants.ErrorBlock, ErrorBlock);
    this.addModule(ComponentFactoryConstants.ArticleBody, ArticleBody);
    this.addModule(ComponentFactoryConstants.CloudinaryImage, CloudinaryImage);
    this.addModule(ComponentFactoryConstants.YoutubeVideo, YoutubeVideo);
    this.addModule(ComponentFactoryConstants.ResponsiveImage, ResponsiveImage);
    this.addModule(ComponentFactoryConstants.ContactUsBlock, ContactUsBlock);
    this.addModule(ComponentFactoryConstants.LoginBlock, LoginBlock);
    this.addModule(ComponentFactoryConstants.ForgotPasswordBlock, ForgotPasswordBlock);
    this.addModule(ComponentFactoryConstants.ResetPasswordBlock, ResetPasswordBlock);
    this.addModule(ComponentFactoryConstants.CreateAccountBlock, CreateAccountBlock);
    this.addModule(ComponentFactoryConstants.PypestreamChat, PypestreamChat);
    this.addModule(ComponentFactoryConstants.CookiesNotificationOverlay, CookiesNotificationOverlay);
    this.addModule(ComponentFactoryConstants.RegisterTypeCodeBlock, RegisterTypeCodeBlock);
    this.addModule(ComponentFactoryConstants.RegisterProductBlock, RegisterProductBlock);
    this.addModule(ComponentFactoryConstants.CongratulationsBlock, CongratulationsBlock);
    this.addModule(ComponentFactoryConstants.ProfileBlock, ProfileBlock);
    this.addModule(ComponentFactoryConstants.ChangePasswordBlock, ChangePasswordBlock);
    this.addModule(ComponentFactoryConstants.PressQuoteBlock, PressQuoteBlock);
    this.addModule(ComponentFactoryConstants.SearchBlock, SearchComponent);
    this.addModule(ComponentFactoryConstants.LoginNavigationBlock, LoginNavigationBlock);
    this.addModule(ComponentFactoryConstants.DesignedByDentist, DesignedByDentistBlock);
    this.addModule(ComponentFactoryConstants.Awards, AwardsBlock);
    this.addModule(ComponentFactoryConstants.LegacyArticleHeroBlock, LegacyArticleHeroBlock);
    this.addModule(ComponentFactoryConstants.ContentDetailsBlock, ContentDetailsBlock);
    this.addModule(ComponentFactoryConstants.ConsentBlock, ConsentBlock);
    this.addModule(ComponentFactoryConstants.ThreeColumnsUserQuoteBlock, ThreeColumnsUserQuoteBlock);
    this.addModule(ComponentFactoryConstants.ArticleShare, ArticleShare);
    this.addModule(ComponentFactoryConstants.LegacyProductHighlightBlock, LegacyProductHighlightsBlock);
    this.addModule(ComponentFactoryConstants.ArticleListBlock, ArticleListBlock);
    this.addModule(ComponentFactoryConstants.SpotlightContestBlock, SpotlightContest);
    this.addModule(ComponentFactoryConstants.SpotlightContestThankYouBlock, SpotlightContestThankYou);
    this.addModule(ComponentFactoryConstants.ArticleTableBlock, ArticleTableBlock);
    this.addModule(ComponentFactoryConstants.ProductLegacyBody, ProductLegacyBody);
    this.addModule(ComponentFactoryConstants.FaqDetailsBlock, FAQDetailsBlock);
    this.addModule(ComponentFactoryConstants.Disclaimer, Disclaimer);
    this.addModule(ComponentFactoryConstants.ProductVariantsRecommanderBlock, ProductVariantsRecommanderBlock);
    this.addModule(ComponentFactoryConstants.SitemapBlock, SitemapBlock);
    this.addModule(ComponentFactoryConstants.SitemapPage, SitemapPage);
    this.addModule(ComponentFactoryConstants.HTMLBlock, HTMLBlock);
    this.addModule(ComponentFactoryConstants.SpotlightExperienceBlock, SpotlightExperienceBlock);
    this.addModule(ComponentFactoryConstants.DoubleOptInVerificationBlock, DoubleOptInVerificationBlock);
    this.addModule(ComponentFactoryConstants.ClicCarousel, ClicCarousel);
    this.addModule(ComponentFactoryConstants.ClicVideo, ClicVideo);
    this.addModule(ComponentFactoryConstants.PostPurchaseRegisterForm, PostPurchaseRegisterForm);
    this.addModule(ComponentFactoryConstants.PrePurchase, PrePurchase);
    this.addModule(ComponentFactoryConstants.PrePurchaseResult, PrePurchaseResult);
    this.addModule(ComponentFactoryConstants.PostPurchaseWriteReviewSection, PostPurchaseWriteReviewSection);
    this.addModule(ComponentFactoryConstants.BreadCrumbs, BreadCrumbs);
    this.addModule(ComponentFactoryConstants.blockStartGuide, StartGuide);
    this.addModule(ComponentFactoryConstants.blockStartGuideContentBlock, StartGuideContentBlock);
    this.addModule(ComponentFactoryConstants.pageProgressBar, pageProgressBar);
    this.addModule(ComponentFactoryConstants.blockStartGuideThreeStepBlock, StartGuideShuffleBlock);
    this.addModule(ComponentFactoryConstants.StartGuideSignupForm, StartGuideSignupForm);
    this.addModule(ComponentFactoryConstants.MiniContentBlock, MiniContentBlock);
    this.addModule(ComponentFactoryConstants.SwitchTab, SwitchTab);
    this.addModule(ComponentFactoryConstants.ArticleCarouselBlock, ArticleCarouselBlock)
    this.addModule(ComponentFactoryConstants.DragSlider, DragSlider);
  }

  addModule(moduleName, moduleClass) {
    this.modules[moduleName.toUpperCase()] = moduleClass;
  }

  getModule(moduleClass) {
    const moduleName = moduleClass.toUpperCase();
    return this.modules[moduleName];
  }
}

export default new ComponentFactory();
